import { lazy } from "react";

const Main      = lazy(() => import("./Views/Main"));
const SaibaMais = lazy(() => import("./Views/SaibaMais"));


export const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/saiba-mais",
    name: "Saiba mais",
    component: SaibaMais,
  },
];
