import "./App.css";
import { Suspense, lazy, useMemo } from "react";
import { routes }                  from "./routes";
import { Loading }                 from "./Components/Loading";

function App() {
  const route = useMemo(() => {
    return (
      routes.find(({ path }) => path === window.location.pathname) || routes[0]
    );
  }, []);
  if (!route) {
    return null;
  }
  const Navbar = lazy(() => import("./Components/Navbar"));
  const Footer = lazy(() => import("./Components/Footer"));
  const ScrollToTopButton = lazy(() => import("./Components/GoUpBtn"));
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Navbar />
        <route.component />
        <Footer />
        <ScrollToTopButton />
      </Suspense>
    </div>
  );
}

export default App;
